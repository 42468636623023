import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function LittleLionMan({ data }) {
  return (
    <Layout>
      <Helmet title="Little Lion Man | Mumford & Sons" />

      <h1>Little Lion Man</h1>

      <h2>Mumford & Sons</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>Weep for yourself, my man</p>
          <p>You'll never be what is in your heart</p>
          <p>Weep, little lion man</p>
          <p>You're not as brave as you were at the start</p>
          <p>Rate yourself and rake yourself</p>
          <p>Take all the courage you have left</p>
          <p>And waste it on fixing all the problems</p>
          <p>That you made in your own head</p>
        </Verse>
        <Chorus>
          <p>But it was not your fault but mine</p>
          <p>And it was your heart on the line</p>
          <p>I really fucked it up this time</p>
          <p>Didn't I, my dear?</p>
          <p>Didn't I, my-</p>
        </Chorus>
        <Verse>
          <p>Tremble for yourself, my man</p>
          <p>You know that you have seen this all before</p>
          <p>Tremble, little lion man</p>
          <p>You'll never settle any of your scores</p>
          <p>Your grace is wasted in your face</p>
          <p>Your boldness stands alone among the wreck</p>
          <p>Now learn from your mother or else</p>
          <p>Spend your days biting your own neck</p>
        </Verse>
        <Chorus>
          <p>But it was not your fault but mine</p>
          <p>And it was your heart on the line</p>
          <p>I really fucked it up this time</p>
          <p>Didn't I, my dear?</p>
          <p>But it was not your fault but mine</p>
          <p>And it was your heart on the line</p>
          <p>I really fucked it up this time</p>
          <p>Didn't I, my dear?</p>
          <p>Didn't I, my dear?</p>
        </Chorus>
        <Bridge>
          <p>Ooooh, oooh, oooh ooh</p>
        </Bridge>
        <Chorus>
          <p>But it was not your fault but mine</p>
          <p>And it was your heart on the line</p>
          <p>I really fucked it up this time</p>
          <p>Didn't I, my dear?</p>
          <p>But it was not your fault but mine</p>
          <p>And it was your heart on the line</p>
          <p>I really fucked it up this time</p>
          <p>Didn't I, my dear?</p>
          <p>Didn't I, my dear?</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "little-lion-man.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;